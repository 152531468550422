import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import PageBase from '@/components/common/Page'
import SEO from '@/components/head/seo'
import LD from '@/components/head/linking-data'
import LDEntity from '@/utils/linking-data'
import { mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import PageHead from '@/components/app/PageHead'
import Content from '@/components/common/Content'
import Breadcrumb from '@/components/app/Breadcrumb.container'
import ConversionLead from '@/components/modules/Conversion/Lead'

const News = props => {
  const { site, markdownRemark } = props.data
  const siteMeta = site.siteMetadata
  const { frontmatter, html, excerpt, fields } = markdownRemark
  const post = {
    ...fields,
    ...frontmatter,
    html,
    excerpt
  }
  return (
    <Root>
      <SEO title={post.title} description={post.description || post.excerpt} location={props.location} />
      <LD json={{
        '@type': 'WebPage',
        '@id': `${siteMeta.siteUrl}${post.slug}#webpage`,
        name: `${post.title} | ${siteMeta.title}`,
        description: post.description,
        url: `${siteMeta.siteUrl}${post.slug}`,
        inLanguage: 'ja',
        datePublished: post.date,
        dateModified: post.date,
        isPartOf: LDEntity.WebSite(siteMeta)
      }}/>
      <LD json={{
        '@type': 'Article',
        name: `${post.title} | ${siteMeta.title}`,
        datePublished: post.date,
        dateModified: post.date,
        author: LDEntity.Organization(siteMeta),
        headline: post.title,
        publisher: LDEntity.Organization(siteMeta),
        mainEntityOfPage: `${siteMeta.siteUrl}${post.slug}`
      }}/>
      <Breadcrumb title={post.title} path={post.slug}/>
      <Head>
        <PageHead
          title={post.title}
          date={post.date}
          tags={post.tags}
        />
      </Head>
      <Main>
        <Content css={styles.Content} html={post.html}/>
        <ConversionLead />
      </Main>
    </Root>
  )
}

const Root = styled.div``

const Head = styled.div``

const Main = styled.div``

const styles = {
  Breadcrumb: css`
    padding: 0;
    @media ${mq.and(mobile)} {
      padding: 40px 0 0;
    }
  `,
  Content: css`
    padding: 100px 0;
    @media ${mq.and(mobile)} {
      padding: 48px 0 64px;
    }
  `
}

export default PageBase(News)

export const PageTemplateQuery = graphql`
  query NewsTemplateQuery($path: String!) {
    site {
      siteMetadata {
        siteUrl
        title
        description
        keywords
        image
        company {
          name
          logo
          siteUrl
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $path } }) {
      html
      excerpt(truncate: true)
      fields {
        slug
      }
      frontmatter {
        description
        date
        title
        tags
      }
    }
  }
`
